// NOTE: Week starts on Sunday
export enum Day {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
};

export const DayAsString = {
    [Day.Sunday]: "Sunday",
    [Day.Monday]: "Monday",
    [Day.Tuesday]: "Tuesday",
    [Day.Wednesday]: "Wednesday",
    [Day.Thursday]: "Thursday",
    [Day.Friday]: "Friday",
    [Day.Saturday]: "Saturday",
} as const;

export const AllDaysOfTheWeek = [
    Day.Sunday,
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
    Day.Saturday,
] as const;
