import { Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BailRule } from '../models/bail-rules';

@Directive()
export abstract class BaseBailRule<DataType = any> {

  @Input() rule?: BailRule<DataType>;

  constructor() { }

  abstract getFormGroup(): FormGroup;
}
