import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { BehaviorSubject, filter, map, mergeMap, Observable, tap } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { IUserData } from '../models/user-data';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    domainPrefix = '';

    configuration$: Observable<OpenIdConfiguration> = this.oidcSecurityService.getConfiguration();
    userData$: Observable<IUserData> = this.config.configHasLoaded$.pipe(filter(x => x), mergeMap(() => this.oidcSecurityService.userData$.pipe(
        map(({ userData }) => userData),
        // Map the tenants claim to the correct format
        map(userData => {
            if (!userData) {
                return userData;
            }

            const tenants = userData?.[`${this.domainPrefix}/tenants`] ?? userData.tenants;
            if (!tenants) {
                return userData;
            }

            return {
                tenantIds: typeof tenants === 'string' ? tenants.split(',') : tenants,
                ...userData
            }
        }),
        // Gets only the keys as defined in the UserData class which at minimum implements IUserData
        // removes any unneeded information from object
        // map(userData => userData && extractObject(userData, ...Object.keys(new UserData())) as IUserData),

        // Set the current tenant in use as the first by default
        tap(x => x?.tenantIds && this.currentTenantSubject.next(x.tenantIds[0]))
    )));
    isAuthenticated$: Observable<boolean> = this.oidcSecurityService.isAuthenticated$.pipe(map(({ isAuthenticated }) => isAuthenticated));

    private currentTenantSubject = new BehaviorSubject<string | undefined>(undefined);
    currentTenant$ = this.currentTenantSubject.asObservable();
    currentTenant?: string;

    constructor(private oidcSecurityService: OidcSecurityService, private router: Router, private config: ConfigService) {
        this.config.getConfig().subscribe(({ authClaimsNamespace }) => this.domainPrefix = authClaimsNamespace);
    }

    init() {
        this.oidcSecurityService.checkAuth().subscribe();
    }

    setTenant(tenant: string) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.currentTenantSubject.next(tenant);
        this.router.navigateByUrl("/", { skipLocationChange: true });
    }

    login() {
        this.oidcSecurityService.authorize();
    }

    refreshSession() {
        this.oidcSecurityService.forceRefreshSession().subscribe();
    }

    logout() {
        this.oidcSecurityService.logoff();
    }

    logoffAndRevokeTokens() {
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
            this.currentTenantSubject.next(undefined);
            this.router.navigateByUrl("/");
        });
    }

    revokeRefreshToken() {
        this.oidcSecurityService.revokeRefreshToken().subscribe();
    }

    revokeAccessToken() {
        this.oidcSecurityService.revokeAccessToken().subscribe();
    }
}

// extractObject creates a new object with only the keys listed, removes unused data
function extractObject<U extends Record<string, unknown>, K extends keyof U>(
    object: U,
    ...keys: K[]
): Pick<U, K> {
    return keys.reduce(
        (prev, current) => ({ ...prev, [current]: object[current] }),
        {} as Pick<U, K>
    );
}

