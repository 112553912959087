import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { BooleanFeatureFlag } from '../feature-flag.type';
import { FeatureFlagService } from '../services/feature-flag.service';

@Component({
    selector: 'feature-flag',
    template: `
        <ng-container *ngIf="areFlagsSetAndTrue">
            <ng-content></ng-content>
        </ng-container>
        <ng-container *ngIf="hasTemplateAndFlagNotSet">
            <ng-template [ngTemplateOutlet]="notFlag"></ng-template>
        </ng-container>
    `,
    styles: [
        `
        :host {
            display: contents;
        }
        `
    ]
})
export class FeatureFlagComponent implements OnChanges {

    @Input() flag?: BooleanFeatureFlag;
    @Input() flags: BooleanFeatureFlag[] = [];
    @Input('else') notFlag: TemplateRef<any> | null = null;

    areFlagsSetAndTrue = false;
    hasTemplateAndFlagNotSet = false;

    constructor(public features: FeatureFlagService) { }

    ngOnChanges(changes: SimpleChanges) {

        const flag = changes['flag']?.currentValue;
        const flags = changes['flags']?.currentValue ?? [];

        const allFlags = [ ...flags, flag ].filter(x => x !== undefined);

        this.areFlagsSetAndTrue = this.features.HasAllBooleanFeatureFlagsSet(allFlags);
        this.hasTemplateAndFlagNotSet = !this.areFlagsSetAndTrue && this.notFlag !== null;
    }
}
