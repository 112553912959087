import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, catchError, filter, map, mergeMap, Observable, ObservableInput, of, tap, throwError } from 'rxjs';
import { KioskGroup, Tenant } from 'src/app/auth/models/tenants';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IPagedResults, IPagedResultsQuery, PagedResultsFromTotal } from 'src/app/lib/api';
import { ConfigService } from 'src/app/services/config.service';
import { BailAttendanceReport, BailAttendanceReportQuery, BailAttendee, BailAttendeeDetail, BailAttendeeID, BailAttendeePhoto, BailAttendeeQuery } from '../models/bail-attendee';

@Injectable({
    providedIn: 'root'
})
export class BailAdminService {

    // Get Tenants one request at a time
    // userTenants$: Observable<Tenant[]> = this.auth.userData$.pipe(mergeMap(userData => userData.tenantIds.map(x => this.getTenant(x))), combineLatestAll());

    // Get Tenants in a single request
    userTenants$: Observable<Tenant[]> = this.auth.userData$.pipe(mergeMap(userData => this.getManyTenants(userData?.tenantIds ?? [])));

    private API_URL: string = '';

    private tenantId?: string = this.auth.currentTenant;
    private kioskGroupId: string = '';

    private hasTenantInformationSubject = new BehaviorSubject(false);
    hasTenantInformation$ = this.config.configHasLoaded$.pipe(filter(x => x), mergeMap(() => this.hasTenantInformationSubject.asObservable()));

    constructor(private http: HttpClient, private snackbar: MatSnackBar, private auth: AuthService, private config: ConfigService) {
        this.config.config$.subscribe(config => {
            if(config) {
                this.API_URL = config.apiUrl;
            }
        });

        this.auth.currentTenant$.subscribe(tenantId => {
            this.tenantId = tenantId;
            if(tenantId) {
                this.getKioskGroups(tenantId).subscribe(kioskGroups => {
                    this.kioskGroupId = kioskGroups[0].kioskGroupId;
                    this.hasTenantInformationSubject.next(true);
                });
            } else {
                this.kioskGroupId = '';
                this.hasTenantInformationSubject.next(false);
            }
        });
    }

    searchAttendees(query: BailAttendeeQuery & Partial<IPagedResultsQuery>): Observable<IPagedResults<BailAttendee>> {
        if (!this.tenantId) {
            return of(new PagedResultsFromTotal([], 0).page(0));
        }
        const { searchText, statusFilter, pageIndex, recordsPerPage } = query;

        let stringifiedQuery: any = { searchText: encodeURIComponent(searchText), statusFilter: statusFilter.toString() };

        if (pageIndex) {
            stringifiedQuery.pageIndex = pageIndex.toString();
        }

        if (recordsPerPage) {
            stringifiedQuery.recordsPerPage = recordsPerPage.toString();
        }

        const params = new URLSearchParams(stringifiedQuery);
        return this.hasTenantInformation$.pipe(filter(x => x), mergeMap(() =>
            this.http.get<IPagedResults<BailAttendee>>(`${this.API_URL}/admin/tenants/${this.tenantId}/kiosk-groups/${this.kioskGroupId}/attendees?${params.toString()}`)
            .pipe(catchError(err => this.handleErrors(err)))
        ));
    }

    getAttendee(id: BailAttendeeID): Observable<BailAttendeeDetail> {
        return this.hasTenantInformation$.pipe(filter(x => x), mergeMap(() =>
            this.http.get<BailAttendeeDetail>(`${this.API_URL}/admin/tenants/${this.tenantId}/kiosk-groups/${this.kioskGroupId}/attendees/${id}`).pipe(catchError(err => this.handleErrors(err)))
        ));
    }

    getAttendeePhotos(query: { id: BailAttendeeID } & Partial<IPagedResultsQuery>): Observable<IPagedResults<BailAttendeePhoto>> {
        const id = query.id.toString();
        const pageIndex = query.pageIndex?.toString() ?? '1';
        const recordsPerPage = query.recordsPerPage?.toString() ?? '10';

        const params = new URLSearchParams({ id, pageIndex, recordsPerPage });
        return this.hasTenantInformation$.pipe(filter(x => x), mergeMap(() =>
            this.http.get<IPagedResults<BailAttendeePhoto>>(`${this.API_URL}/admin/tenants/${this.tenantId}/kiosk-groups/${this.kioskGroupId}/attendees/${id}/photos?${params.toString()}`)
        ));
    }

    deleteAttendee(id: BailAttendeeID): Observable<void> {
        return this.hasTenantInformation$.pipe(filter(x => x), mergeMap(() =>
            this.http.delete<void>(`${this.API_URL}/admin/tenants/${this.tenantId}/kiosk-groups/${this.kioskGroupId}/attendees/${id}`).pipe(
                catchError(err => this.handleErrors(err)),
                tap(() => this.snackbar.open(`attendee deleted`))
            )
        ));
    }

    checkPhoto(photo: string): Observable<boolean> {
        // disregard problemDescription, detectedFaceBox etc..
        if(!photo) {
            this.snackbar.open('Please provide a photo');
            return of(false);
        }
        return this.config.configHasLoaded$.pipe(filter(x => x), mergeMap(() =>
            this.http.post<{ verifyOK: boolean, problemDescription: string }>(`${this.API_URL}/admin/face/verify-photo`, { photo }).pipe(catchError(err => this.handleErrors(err)),
                tap(({ verifyOK, problemDescription }) => !verifyOK && this.snackbar.open(problemDescription ?? 'The uploaded photo did not succeed and a face could not be recognised, please try a different photo')),
                map(({ verifyOK }) => verifyOK)
            )
        ));
    }

    createAttendee(attendee: BailAttendeeDetail): Observable<string> {
        const newAttendee: any = attendee;
        delete newAttendee.attendeeId;
        return this.hasTenantInformation$.pipe(filter(x => x), mergeMap(() =>
            this.http.post<void>(`${this.API_URL}/admin/tenants/${this.tenantId}/kiosk-groups/${this.kioskGroupId}/attendees`, attendee).pipe(
                catchError(err => this.handleErrors(err)),
                tap(() => this.snackbar.open(`Attendee ${attendee.personURN} created`)),
                map(res => res.attendeeId ?? "")
            )
        ));
    }

    updateAttendee(id: BailAttendeeID, attendee: Partial<BailAttendeeDetail>): Observable<void> {
        return this.hasTenantInformation$.pipe(filter(x => x), mergeMap(() =>
            this.http.put<void>(`${this.API_URL}/admin/tenants/${this.tenantId}/kiosk-groups/${this.kioskGroupId}/attendees/${id}`, attendee).pipe(
                catchError(err => this.handleErrors(err)),
                tap(() => this.snackbar.open(`Attendee ${attendee.personURN} saved`))
            )
        ));
    }

    searchReports(query: BailAttendanceReportQuery & Partial<IPagedResultsQuery>): Observable<IPagedResults<BailAttendanceReport>> {
        if (!this.tenantId) {
            return of(new PagedResultsFromTotal([], 0).page(0));
        }
        const { searchText, toDate, fromDate, statusFilter, pageIndex, recordsPerPage } = query;

        let stringifiedQuery: any = { searchText: encodeURIComponent(searchText), statusFilter: statusFilter.toString() };

        stringifiedQuery.toDate = typeof toDate === 'string' ? toDate : (toDate as Date).toISOString();
        stringifiedQuery.fromDate = typeof fromDate === 'string' ? fromDate : (fromDate as Date).toISOString();

        if (pageIndex) {
            stringifiedQuery.pageIndex = pageIndex.toString();
        }

        if (recordsPerPage) {
            stringifiedQuery.recordsPerPage = recordsPerPage.toString();
        }

        const params = new URLSearchParams(stringifiedQuery);
        return this.hasTenantInformation$.pipe(filter(x => x), mergeMap(() =>
            this.http.get<IPagedResults<BailAttendanceReport>>(`${this.API_URL}/admin/tenants/${this.tenantId}/kiosk-groups/${this.kioskGroupId}/attendance-records?${params.toString()}`)
            .pipe(catchError(err => this.handleErrors(err)))
        ));
    }

    private getTenant(tenantId: string): Observable<Tenant> {
        return this.config.configHasLoaded$.pipe(filter(x => x), mergeMap(() =>
            this.http.get<Tenant>(`${this.API_URL}/admin/tenants/${tenantId}`)
        ));
    }

    private getManyTenants(tenantIds: string[]): Observable<Tenant[]> {
        if(!tenantIds.length) {
            return of([]);
        }
        return this.config.configHasLoaded$.pipe(filter(x => x), mergeMap(() =>
            this.http.post<Tenant[]>(`${this.API_URL}/admin/tenants/get-many`, { tenantIds })
        ));
    }

    getKioskGroups(tenantId: string): Observable<KioskGroup[]> {
        return this.config.configHasLoaded$.pipe(filter(x => x), mergeMap(() =>
            this.http.get<KioskGroup[]>(`${this.API_URL}/admin/tenants/${tenantId}/kiosk-groups`)
        ));
    }

    private handleErrors(error: HttpErrorResponse): ObservableInput<any> {
        if (error.status === 0) {
            this.snackbar.open('There was a network error, please check your network settings and try again');
        } else {
            const errorDetail: string | undefined = error?.error?.title ?? error?.error?.detail;
            this.snackbar.open('Error: ' + errorDetail ?? 'unknown, contact your server admin');
        }
        return throwError(() => new Error(error.message));
    }
}
