import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseBailRule } from '../directives/base-bail-rule.directive';
import { BailRuleHandler, BailRuleType, NamedRegisteredRule, RegisteredRuleHandler } from '../models/bail-rules';

@Injectable({
  providedIn: 'root'
})
export class BailRulesService {

  private bailRuleHandlers = new Map<BailRuleType, RegisteredRuleHandler>();

  saveRules$ = new Subject<void>();

  constructor() { }

  registerType<T extends BaseBailRule<any>>(ruleType: BailRuleType, component: BailRuleHandler<T>, displayName: string): void {
    if (this.bailRuleHandlers.has(ruleType)) {
      console.warn('Replacing an existing rule handler');
    }
    this.bailRuleHandlers.set(ruleType, { component, displayName });
  }

  getComponentType(ruleType: BailRuleType): BailRuleHandler | undefined {
    return this.bailRuleHandlers.get(ruleType)?.component;
  }

  getRuleDisplayName(ruleType: BailRuleType): string | undefined {
    return this.bailRuleHandlers.get(ruleType)?.displayName;
  }

  getAllRegisteredRulesAndNames(): NamedRegisteredRule[] {
    let registeredRules: NamedRegisteredRule[] = [];
    this.bailRuleHandlers.forEach((value, key) => registeredRules.push({ rule: key, displayName: value.displayName }));
    return registeredRules;
  }

  saveRules() {
    this.saveRules$.next();
  }
}
