import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseBailRule } from 'src/app/bail-rules/directives/base-bail-rule.directive';
import { Day } from 'src/app/models/time';

export interface RecurringDaysHandlerData {
    recurringDays: {
        dayOfWeek: Day
        startTime: Date | string
        endTime: Date | string
    }[]
}

@Component({
    selector: 'app-recurring-days-rule-handler',
    templateUrl: './recurring-days-rule-handler.component.html',
    styleUrls: ['./recurring-days-rule-handler.component.scss']
})
export class RecurringDaysRuleHandlerComponent extends BaseBailRule<RecurringDaysHandlerData> implements OnInit {

    Day = Day;

    formGroup = new FormGroup({
        recurringDays: new FormArray([
            new FormGroup({
                dayOfWeek: new FormControl<Day>(Day.Monday),
                startTime: new FormControl<Date | string | null>("09:00", Validators.required),
                endTime: new FormControl<Date | string | null>("17:00", Validators.required),
            })
        ])
    });

    get dailyBailConditions(): FormArray {
        return this.formGroup.get('recurringDays') as FormArray;
    }

    ngOnInit(): void {
        // Remove default bail condition if conditions already exist
        if (this.rule?.data?.recurringDays.length) {
            this.removeBailCondition(0);

            // Add bail conditions
            this.rule?.data.recurringDays.forEach(dayAndTime => {
                this.addBailCondition(dayAndTime.dayOfWeek, dayAndTime.startTime, dayAndTime.endTime);
            })
        }

    }

    getFormGroup(): FormGroup {
        return this.formGroup;
    }

    addBailCondition(day: Day = Day.Monday, startTime: Date | string | null = "09:00", endTime: Date | string | null = "17:00") {
        this.dailyBailConditions.push(new FormGroup({
            dayOfWeek: new FormControl<Day>(day),
            startTime: new FormControl<Date | string | null>(startTime, Validators.required),
            endTime: new FormControl<Date | string | null>(endTime, Validators.required),
        }));
    }

    removeBailCondition(index: number) {
        this.dailyBailConditions.removeAt(index);
    }
}
