<mat-toolbar class="sticky top-0 left-0 z-100" color="primary">
    <a mat-icon-button routerLink="/home">
        <mat-icon>home</mat-icon>
    </a>
    <span class="flex-grow"></span>
    <div *ngIf="userData$ | async as userData; else login">
        <button mat-button [matMenuTriggerFor]="userMenu" aria-label="Your actions">
            <div class="inline-flex gap-2 items-center">
                <span>{{ userData.name }}</span>
                <img [src]="userData.picture" class="rounded-circle img-icon" *ngIf="userData.picture">
            </div>
        </button>
        <mat-menu #userMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="tenants">
                <mat-icon>domain</mat-icon>
                Switch Tenants
            </button>
            <mat-divider></mat-divider>
            <a mat-menu-item routerLink="/admin/new-registrants">
                <mat-icon>group_add</mat-icon>
                <span>New Registrants</span>
            </a>
            <feature-flag [flag]="CanAddAttendeeFlag">
                <a mat-menu-item routerLink="/admin/new-attendee">
                    <mat-icon>person_add</mat-icon>
                    <span>Add Attendee</span>
                </a>
            </feature-flag>
            <a mat-menu-item routerLink="/admin/search">
                <mat-icon>search</mat-icon>
                <span>Search Attendees</span>
            </a>
            <a mat-menu-item routerLink="/admin/reports">
                <mat-icon>report</mat-icon>
                <span>Reports</span>
            </a>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logoutUser()">
                <mat-icon>meeting_room</mat-icon>
                <span>Logout</span>
            </button>
            <mat-menu #tenants="matMenu">
                <ng-container *ngIf="currentTenant$ | async as currentTenant">
                    <ng-container *ngFor="let tenant of tenants$ | async">
                        <button mat-menu-item (click)="switchTenant(tenant.tenantId)">
                            <mat-icon
                                *ngIf="currentTenant.toUpperCase() === tenant.tenantId.toUpperCase()">done</mat-icon>
                            {{ tenant.name }}
                        </button>
                    </ng-container>
                </ng-container>
            </mat-menu>
        </mat-menu>
    </div>
    <ng-template #login>
        <button mat-button (click)="loginUser()">
            Login
        </button>
    </ng-template>
</mat-toolbar>
