import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { BaseBailRule } from 'src/app/bail-rules/directives/base-bail-rule.directive';
import { AllDaysOfTheWeek, Day, DayAsString } from 'src/app/models/time';

export interface RecurringDaysSingleSessionHandlerData {
    recurringDays: {
        dayOfWeek: Day
        startTime: Date | string
        endTime: Date | string
    }[]
}

@Component({
    selector: 'app-recurring-days-single-session-rule-handler',
    templateUrl: './recurring-days-single-session-rule-handler.component.html',
    styleUrls: ['./recurring-days-single-session-rule-handler.component.scss']
})
export class RecurringDaysSingleSessionRuleHandlerComponent extends BaseBailRule<RecurringDaysSingleSessionHandlerData> implements OnInit {

    readonly DayAsString = DayAsString;

    readonly Day = Day;

    readonly AllDaysOfTheWeek = AllDaysOfTheWeek;

    isAddingNewDay = true;

    availableDays = [
        Day.Sunday,
        // Day.Monday,
        Day.Tuesday,
        Day.Wednesday,
        Day.Thursday,
        Day.Friday,
        Day.Saturday,
    ];

    formGroup = new FormGroup({
        recurringDays: new FormArray([
            new FormGroup({
                dayOfWeek: new FormControl<Day>(Day.Monday),
                startTime: new FormControl<Date | string | null>("09:00", Validators.required),
                endTime: new FormControl<Date | string | null>("17:00", Validators.required),
            })
        ])
    });

    get dailyBailConditions(): FormArray {
        return this.formGroup.get('recurringDays') as FormArray;
    }

    ngOnInit(): void {
        // Remove default bail condition if conditions already exist
        if (this.rule?.data?.recurringDays.length) {
            this.removeBailCondition(0);

            // Add bail conditions
            this.rule?.data.recurringDays.forEach(dayAndTime => {
                this.addBailCondition(dayAndTime.dayOfWeek, dayAndTime.startTime, dayAndTime.endTime);
            })
        }

    }

    getFormGroup(): FormGroup {
        return this.formGroup;
    }

    addBailCondition(day: Day = this.availableDays[0] ?? Day.Monday, startTime: Date | string | null = "09:00", endTime: Date | string | null = "17:00") {
        const dayIndex = this.availableDays.findIndex(x => x === day);
        if (dayIndex === -1) {
            return;
        }
        this.isAddingNewDay = true;

        this.availableDays.splice(dayIndex, 1);

        this.dailyBailConditions.push(new FormGroup({
            dayOfWeek: new FormControl<Day>(day),
            startTime: new FormControl<Date | string | null>(startTime, Validators.required),
            endTime: new FormControl<Date | string | null>(endTime, Validators.required),
        }));

        if(!this.availableDays.length) {
            this.dailyBailConditions.controls.forEach(x => x.get('dayOfWeek')?.disable());
        }
    }

    removeBailCondition(index: number) {
        const availableDayToAdd = this.dailyBailConditions.at(index)?.getRawValue()?.dayOfWeek;

        if (availableDayToAdd !== undefined) {
            this.availableDays.push(availableDayToAdd);
        }

        this.availableDays.sort();

        this.dailyBailConditions.removeAt(index);

        this.dailyBailConditions.controls.forEach(x => x.get('dayOfWeek')?.enable());
    }

    daySelectionChanged(event: MatOptionSelectionChange<Day>) {
        if(this.isAddingNewDay) {
            this.isAddingNewDay = false;
            return;
        }

        if (event.isUserInput) {
            // selected value
            const dayIndex = this.availableDays.findIndex(x => x === event.source.value);
            if (dayIndex === -1) {
                return;
            }
            this.availableDays.splice(dayIndex, 1);
        } else {
            // unselected value
            this.availableDays.push(event.source.value);
            this.availableDays.sort();
        }
    }
}
