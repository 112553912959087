import { BooleanFeatureFlag } from "src/app/modules/feature-flag/feature-flag.type";
import { EnvironmentWithFeatureFlags } from "src/app/modules/feature-flag/helpers";
import { Environment } from "./environment-interface";
import { appVersion } from "./version";

export const environment: EnvironmentWithFeatureFlags<Environment> = {
    appVersion,
    production: true,
    useConfigService: true,
    configPath: '/assets/client-config-staging-env.json',
    booleanFeatureFlags: [
        BooleanFeatureFlag.CanAddAttendee,
        BooleanFeatureFlag.CanUploadAndTakePhotos,
        // BooleanFeatureFlag.CanHaveMultipleRules,
        BooleanFeatureFlag.CanUseMultiSessionDayRules,
    ]
};
