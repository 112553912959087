import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor, AuthModule, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { map } from 'rxjs';
import { ConfigService } from '../services/config.service';

export const configLoader = (config: ConfigService) => {
    return new StsConfigHttpLoader(config.getConfig().pipe(map(appConfig => ({
        authority: appConfig.authDomain,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: appConfig.authClientId,
        scope: appConfig.scopes,
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        secureRoutes: [appConfig.apiUrl],
        customParamsAuthRequest: {
            audience: appConfig.authAudienceUrl
        },
        customParamsRefreshTokenRequest: {
            scope: 'openid profile offline_access auth0-user-api-spa',
        },
    }))));
}

@NgModule({
    imports: [
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: configLoader,
                deps: [ConfigService]
            },
        }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    exports: [AuthModule],
})
export class AuthConfigModule { }
