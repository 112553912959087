import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/services/auth.service';
import { RecurringDaysRuleHandlerComponent } from './bail-rules/components/rule-handlers/recurring-days-rule-handler/recurring-days-rule-handler.component';
import { RecurringDaysSingleSessionRuleHandlerComponent } from './bail-rules/components/rule-handlers/recurring-days-single-session-rule-handler/recurring-days-single-session-rule-handler.component';
import { VaryingDaysRuleHandlerComponent } from './bail-rules/components/rule-handlers/varying-days-rule-handler/varying-days-rule-handler.component';
import { VaryingDaysSingleSessionRuleHandlerComponent } from './bail-rules/components/rule-handlers/varying-days-single-session-rule-handler/varying-days-single-session-rule-handler.component';
import { BailRuleType } from './bail-rules/models/bail-rules';
import { BailRulesService } from './bail-rules/services/bail-rules.service';
import { fadeInOut } from './lib/animations';
import { BooleanFeatureFlag } from './modules/feature-flag/feature-flag.type';
import { FeatureFlagService } from './modules/feature-flag/services/feature-flag.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fadeInOut]
})
export class AppComponent implements OnInit {

    // Ensure auth service is always in memory
    constructor(private auth: AuthService, private bailRules: BailRulesService, private features: FeatureFlagService) {
        this.bailRules.registerType(BailRuleType.RecurringDaysWithSingleSessionPerDay, RecurringDaysSingleSessionRuleHandlerComponent, 'Recurring Days - Single Session Per Day');
        this.bailRules.registerType(BailRuleType.RecurringVariableDaysWithSingleSessionPerDay, VaryingDaysSingleSessionRuleHandlerComponent, 'Variable Days - Single Session Per Day');

        if(this.features.GetBooleanFeatureFlagStatus(BooleanFeatureFlag.CanUseMultiSessionDayRules)) {
            this.bailRules.registerType(BailRuleType.RecurringDays, RecurringDaysRuleHandlerComponent, 'Recurring Days');
            this.bailRules.registerType(BailRuleType.RecurringVariableDays, VaryingDaysRuleHandlerComponent, 'Variable Days');
        } else {
            this.bailRules.registerType(BailRuleType.RecurringDays, RecurringDaysSingleSessionRuleHandlerComponent, 'Recurring Days - Converted to single session (saving may result in loss of original data)');
            this.bailRules.registerType(BailRuleType.RecurringVariableDays, VaryingDaysSingleSessionRuleHandlerComponent, 'Variable Days - Converted to single session (saving may result in loss of original data)');
        }
    }

    ngOnInit(): void {
        // Auth service will try to auto authenticate on app load
        this.auth.init();
    }


    showAppVersion = false;
    appVersion = environment.appVersion;

    toggleShowAppVersion() {
        this.showAppVersion = !this.showAppVersion;
    }
}
