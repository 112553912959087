import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BailAdminService } from 'src/app/admin/services/bail-admin.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {

    constructor(private router: Router, private auth: AuthService, private bailAdmin: BailAdminService) { }

    isLoading = false;

    tenantInfoSubscription?: Subscription;
    authenticatedSubscription?: Subscription;

    ngOnInit(): void {
        this.authenticatedSubscription = this.auth.isAuthenticated$.subscribe(isAuthenticated => this.isLoading = isAuthenticated);

        this.tenantInfoSubscription = this.bailAdmin.hasTenantInformation$.subscribe(hasTenantInformation => {
            if(hasTenantInformation) {
                this.router.navigateByUrl('/admin/overview');
            }
        });
    }

    ngOnDestroy(): void {
        this.tenantInfoSubscription?.unsubscribe();
        this.authenticatedSubscription?.unsubscribe();
    }


    login() {
        this.auth.login();
    }

}
