export interface IPagedResults<T> {
    readonly pageIndex: number
    readonly recordsPerPage: number
    readonly recordCount: number
    readonly totalRecords: number
    readonly totalPages: number
    readonly results: T[]
}

export interface IPagedResultsQuery {
    pageIndex: number
    recordsPerPage: number
}

export class PagedResultsFromSinglePage<T> implements IPagedResults<T> {
    readonly recordCount: number
    readonly totalPages: number

    constructor(public readonly results: T[], public readonly pageIndex: number, public readonly recordsPerPage: number, public readonly totalRecords: number, totalPages?: number) {
        this.recordCount = results.length;
        // Assign total pages if provided, otherwise calculate it
        this.totalPages = totalPages ?? (totalRecords / recordsPerPage) + (totalRecords % recordsPerPage) ? 1 : 0;
    }
}

export class PagedResultsFromTotal<T> {
    readonly totalRecords: number;
    readonly totalPages: number;

    constructor(public readonly results: T[], public readonly recordsPerPage: number) {
        this.totalRecords = results.length;
        this.totalPages = (this.totalRecords / recordsPerPage) + (this.totalRecords % recordsPerPage) ? 1 : 0
    }

    page(pageIndex: number): IPagedResults<T> {
        const { recordsPerPage, totalPages, totalRecords } = this;
        const start = pageIndex * recordsPerPage;
        const results = this.results.slice(start, start + recordsPerPage);
        return {
            pageIndex,
            recordsPerPage,
            recordCount: results.length,
            totalRecords,
            totalPages,
            results,
        };
    }
}
