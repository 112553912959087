import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BooleanFeatureFlag } from '../feature-flag.type';
import { EnvironmentWithFeatureFlags } from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

    private static readonly BooleanFeatureFlags: ReadonlyMap<BooleanFeatureFlag, boolean> = new Map(
        ((environment as EnvironmentWithFeatureFlags<typeof environment>)?.booleanFeatureFlags ?? []).map(x => [x, true])
    );

    GetBooleanFeatureFlagStatus(flag: BooleanFeatureFlag): boolean {
        return FeatureFlagService.BooleanFeatureFlags.get(flag) ?? false;
    }

    HasAllBooleanFeatureFlagsSet(flags: BooleanFeatureFlag[]): boolean {
        return flags.length > 0 && flags.every(flag => this.GetBooleanFeatureFlagStatus(flag));
    }
}
