import { ComponentType } from "@angular/cdk/portal";
import { Observable } from "rxjs";
import { BaseBailRule } from "../directives/base-bail-rule.directive";

export enum BailRuleType {
    RecurringDays = 1 << 1,
    RecurringVariableDays = 1 << 2,
    NonRecurrantDates = 1 << 3,
    RecurringDaysWithSingleSessionPerDay = 1 << 4,
    RecurringVariableDaysWithSingleSessionPerDay = 1 << 5,
}

// Truly dynamic type, unlimited possible values
// type BailRuleType = string;

export interface BailRule<T = any> {
    attendeeBailRuleId?: string
    data: T | undefined
    type: BailRuleType
}

export type BailRuleHandler<T extends BaseBailRule = any> = ComponentType<T>;

export interface BailRuleRenderer<DataType = any, HandlerType extends BaseBailRule = any> {
    rule: BailRule<DataType>
    component: BailRuleHandler<HandlerType>
    displayName: string
    dataSubject: Observable<void>
}

export interface RegisteredRuleHandler<T extends BaseBailRule<any> = any> {
    component: BailRuleHandler<T>
    displayName: string
}

export interface NamedRegisteredRule {
    rule: BailRuleType
    displayName: string
}
