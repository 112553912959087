import { Component, OnInit } from '@angular/core';
import { BailAdminService } from 'src/app/admin/services/bail-admin.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BooleanFeatureFlag } from 'src/app/modules/feature-flag/feature-flag.type';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    CanAddAttendeeFlag = BooleanFeatureFlag.CanAddAttendee;

    userData$ = this.auth.userData$;
    currentTenant$ = this.auth.currentTenant$;
    tenants$ = this.bailAdmin.userTenants$;

    constructor(private auth: AuthService, private bailAdmin: BailAdminService) { }

    ngOnInit(): void {
    }

    logoutUser() {
        this.auth.logoffAndRevokeTokens();
    }

    loginUser() {
        this.auth.login();
    }

    switchTenant(tenant: string) {
        this.auth.setTenant(tenant)
    }
}
