import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthConfigModule } from './auth/auth-config.module';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FeatureFlagModule } from "./modules/feature-flag/feature-flag.module";
import { MaterialModule } from './modules/shared/material.module';
import { HomePageComponent } from './pages/home-page/home-page.component';

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        NavigationComponent
    ],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AuthConfigModule,
        MaterialModule,
        FeatureFlagModule
    ]
})
export class AppModule { }
