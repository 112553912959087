<ng-container [formGroup]="formGroup">
    <mat-form-field class="w-full">
        <mat-label>Number of days to attend per week</mat-label>
        <input matInput type="number" formControlName="numberOfDays" [max]="(dailyBailConditions?.length ?? 7) - 1" min="1">
        <mat-hint>Must be less than the number of days specified, otherwise use recurring days</mat-hint>
    </mat-form-field>
    <ng-container formArrayName="recurringDays">
        <div class="flex-responsive gap-1" *ngFor="let condition of dailyBailConditions?.controls; let i=index">
            <ng-container [formGroupName]="i">
                <mat-form-field class="flex-grow">
                    <mat-label>Day</mat-label>
                    <mat-select formControlName="dayOfWeek">
                        <mat-option *ngFor="let day of AllDaysOfTheWeek" [value]="day" [disabled]="!availableDays.includes(day)" (onSelectionChange)="daySelectionChanged($event)">
                            {{ DayAsString[day] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="flex-grow">
                    <mat-label>Start Time</mat-label>
                    <input matInput type="time" formControlName="startTime">
                </mat-form-field>
                <mat-form-field class="flex-grow">
                    <mat-label>End Time</mat-label>
                    <input matInput type="time" formControlName="endTime">
                </mat-form-field>
                <button mat-flat-button color="warn" type="button" class="aligned-button" matTooltip="Delete"
                    (click)="removeBailCondition(i)" [disabled]="dailyBailConditions!.length <= 1">
                    <mat-icon>close</mat-icon>
                </button>
            </ng-container>
        </div>
        <button mat-flat-button class="aligned-button w-full" color="primary" type="button"
                (click)="addBailCondition()" [disabled]="!availableDays.length">Add another day</button>
    </ng-container>
</ng-container>
