import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseBailRule } from 'src/app/bail-rules/directives/base-bail-rule.directive';
import { Day } from 'src/app/models/time';

export interface VaryingDaysHandlerData {
    numberOfDays: number,
    recurringDays: {
        dayOfWeek: Day
        startTime: Date | string
        endTime: Date | string
    }[]
}

@Component({
    selector: 'app-varying-days-rule-handler',
    templateUrl: './varying-days-rule-handler.component.html',
    styleUrls: ['./varying-days-rule-handler.component.scss']
})
export class VaryingDaysRuleHandlerComponent extends BaseBailRule<VaryingDaysHandlerData> {

    Day = Day;

    formGroup = new FormGroup({
        numberOfDays: new FormControl<number>(1, [Validators.min(1), Validators.max(6), x => Validators.max((this.dailyBailConditions?.length ?? 7) - 1)(x)]),
        recurringDays: new FormArray([
            new FormGroup({
                dayOfWeek: new FormControl<Day>(Day.Monday),
                startTime: new FormControl<Date | string | null>("09:00", Validators.required),
                endTime: new FormControl<Date | string | null>("17:00", Validators.required),
            })
        ])
    });

    get dailyBailConditions(): FormArray | undefined {
        return this.formGroup?.get('recurringDays') as FormArray;
    }


    ngOnInit(): void {
        // Remove default bail condition if conditions already exist
        if (this.rule?.data?.recurringDays.length) {
            this.removeBailCondition(0);

            // Add bail conditions
            this.rule?.data.recurringDays.forEach(dayAndTime => {
                this.addBailCondition(dayAndTime.dayOfWeek, dayAndTime.startTime, dayAndTime.endTime);
            })
        }

        if(this.rule?.data?.numberOfDays != undefined) {
            this.formGroup.get('numberOfDays')?.patchValue(this.rule?.data.numberOfDays);
        }

    }

    getFormGroup(): FormGroup<any> {
        return this.formGroup;
    }

    addBailCondition(day: Day = Day.Monday, startTime: Date | string | null = "09:00", endTime: Date | string | null = "17:00") {
        this.dailyBailConditions?.push(new FormGroup({
            dayOfWeek: new FormControl<Day>(day),
            startTime: new FormControl<Date | string | null>(startTime, Validators.required),
            endTime: new FormControl<Date | string | null>(endTime, Validators.required),
        }));
        this.formGroup.get('numberOfDays')?.updateValueAndValidity();
        this.formGroup.get('numberOfDays')?.markAsTouched();
    }

    removeBailCondition(index: number) {
        this.dailyBailConditions?.removeAt(index);
        this.formGroup.get('numberOfDays')?.updateValueAndValidity();
        this.formGroup.get('numberOfDays')?.markAsTouched();
    }
}
