import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../models/config';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    private configSubject = new BehaviorSubject<AppConfig | undefined>(undefined);
    config$ = this.configSubject.asObservable();
    configHasLoaded$ = this.configSubject.pipe(map(config => !!config));

    constructor(private http: HttpClient) { }

    getConfig(): Observable<AppConfig> {
        if(environment.useConfigService && environment.configPath) {
            return this.http.get<AppConfig>(environment.configPath).pipe(tap(config => this.configSubject.next(config)));
        } else {
            const { scopes, authDomain, authClientId, authAudienceUrl, authClaimsNamespace, apiUrl } = environment;
            if(!scopes || !authDomain || !authClientId || !authAudienceUrl || !authClaimsNamespace || !apiUrl) {
                throw `
                    Environment file was not provided with enough settings; the object must have no undefined variables:
                    ${ { scopes, authDomain, authClientId, authAudienceUrl, authClaimsNamespace, apiUrl } }
                `;
            }

            const config = { scopes, authDomain, authClientId, authAudienceUrl, authClaimsNamespace, apiUrl };
            return of(config).pipe(tap(x => this.configSubject.next(x)));
        }
    }
}
